import React from 'react'

import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Layout from '../components/layout'

const PrivacyPolicyPage = () => (
    <Layout>
        <Header/>
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' className="section privacy">
        <div className="container">
        <h1>Politika privatnosti</h1>

        <p>Datum stupanja na snagu: 20. septembra 2018</p>


        <p>Ova stranica vas obaveštava o našim pravilima o prikupljanju, korišćenju i otkrivanju ličnih podataka kada koristite našu uslugu i izbore koje ste povezali sa tim podacima.</p>

        <p>Mi koristimo vaše podatke za pružanje i poboljšanje usluge. Koristeći Službu, slažete se s prikupljanjem i korištenjem informacija u skladu s ovim pravilima. Ako nije drugačije definisano u ovoj Politici privatnosti, termini koji se koriste u ovoj Politici privatnosti imaju isto značenje kao u našim odredbama i uslovima, dostupnim od vipcars.ba</p>


        <h2>Sakupljanje i korišćenje informacija</h2>

        <p>Sakupljamo nekoliko različitih vrsta informacija za različite svrhe kako bismo vam pružili i unapredili našu uslugu.</p>

        <h3>Vrste prikupljenih podataka</h3>

        <h4>Lični podaci</h4>

        <p>Dok koristimo našu uslugu, možemo vas zamoliti da nam date određene lične informacije koje se mogu koristiti za kontakt ili identifikaciju vas ("Lični podaci"). Lične informacije mogu uključivati, ali se ne ograničavaju na:</p>

        <ul>
        <li>E-mail adresa</li><li>Ime i prezime</li><li>Telefonski broj</li><li>Adresa, država, provincija, poštanski broj, grad</li><li>Kolačići i podaci o upotrebi</li>
        </ul>

        <h4>Podaci o korišćenju</h4>

        <p>Takođe možemo prikupljati informacije o tome kako se Usluga pristupa i koristi ("Podaci o korišćenju"). Podaci o korišćenju mogu uključivati informacije kao što su adresa Internet protokola vašeg računara (npr. IP adresa), tip pretraživača, verzija pretraživača, stranice naše usluge koje posjećujete, vrijeme i datum vaše posjete, vrijeme provedeno na tim stranama, jedinstveni identifikatora uređaja i drugih dijagnostičkih podataka.</p>

        <h4>Podaci o praćenju i kolačićima</h4>
        <p>Koristimo kolačiće i slične tehnologije praćenja kako bismo pratili aktivnosti na našem servisu i držali određene informacije.</p>
        <p>Kolačići su datoteke sa malom količinom podataka koji mogu uključiti anonimni jedinstveni identifikator. Kolačići se šalju u vaš pretraživač sa web stranice i čuvaju na vašem uređaju. Tehnologije praćenja takođe koriste beacons, tagove i skripte za prikupljanje i praćenje informacija i poboljšanje i analiziranje naše usluge.</p>
        <p>Možete da naložite svom pretraživaču da odbije sve kolačiće ili da naznači kada se kolačići šalju. Međutim, ako ne prihvatate kolačiće, možda nećete moći da koristite neke delove naše usluge.</p>
        <p>Primjeri kolačića koje koristimo:</p>
        <ul>
            <li><strong>Cookies sesije.</strong> Koristimo Session Cookies da koristimo našu uslugu.</li>
            <li><strong>Preferencije Cookies.</strong> Koristimo Preference Cookies da zapamtimo vaše želje i različita podešavanja.</li>
            <li><strong>Bezbjednosni kolačići.</strong> Za sigurnosne svrhe koristimo sigurnosne kolačiće.</li>
        </ul>

        <h2>Upotreba podataka</h2>
            
        <p>VIP automobili koriste prikupljene podatke za različite svrhe:</p>    
        <ul>
            <li>Obezbjeđivanje i održavanje Službe</li>
            <li>Da vas obavijestimo o promjenama na našem servisu</li>
            <li>Da bismo vam omogućili da učestvujete u interaktivnim funkcijama našeg servisa kada odlučite da to učinite</li>
            <li>Za pružanje brige o kupcima i podršci</li>
            <li>Da pružimo analizu ili vrijedne informacije kako bismo poboljšali Službu</li>
            <li>Da pratite korišćenje Službe</li>
            <li>Da biste otkrili, spriječili i riješili tehnička pitanja</li>
        </ul>

        <h2>Prenos podataka</h2>
        <p>Vaše informacije, uključujući i Lične podatke, mogu biti prenijete na računare koji se nalaze izvan vaše države, pokrajine, zemlje ili druge nadležnosti države, u kojima se zakoni o zaštiti podataka mogu razlikovati od onih iz vaše nadležnosti</p>
        <p>Ako se nalazite izvan Bosne i Hercegovine i odlučite da nam pružite informacije, imajte na umu da podatke, uključujući lične podatke, prebacujemo u Bosnu i Hercegovinu i obradimo ih tamo.</p>
        <p>Vaša saglasnost na ovu Politiku privatnosti koju pratite podnošenje takvih informacija predstavlja vašu saglasnost za taj transfer.</p>
        <p>VIP automobili će preduzeti sve korake koji su razumno potrebni kako bi se osiguralo da se vaši podaci tretiraju sigurno iu skladu s ovom Politikom privatnosti i da se neće prenositi vaši lični podaci organizaciji ili državi, osim ako postoje odgovarajuće kontrole uključujući bezbednost podatke i druge lične informacije.</p>

        <h2>Objelodanjivanje podataka</h2>

        <h3>Pravni zahtevi</h3>
        <p>VIP automobili mogu objaviti vaše lične podatke u uvjerenju da je takav postupak potreban za:</p>
        <ul>
            <li>Da se pridržavate zakonske obaveze</li>
            <li>Da zaštitite i branite prava ili imovinu VIP automobila</li>
            <li>Da biste sprečili ili istražili moguće pogrešne radnje u vezi sa Servisom</li>
            <li>Zaštititi ličnu sigurnost korisnika Službe ili javnosti</li>
            <li>Zaštititi od pravne odgovornosti</li>
        </ul>

        <h2>Bezbednost podataka</h2>
        <p>Bezbednost vaših podataka je za nas važna, ali zapamtite da nijedan način prenosa preko Interneta ili metod elektronske memorije nije 100% siguran. Iako težimo da koristimo komercijalno prihvatljiva sredstva za zaštitu vaših ličnih podataka, ne možemo garantovati njegovu apsolutnu sigurnost.</p>

        <h2>Pružaoci usluga</h2>
        <p>Možemo da zapošljavamo treća lica i pojedince da olakšaju našu uslugu ("pružalac usluga"), da pružimo uslugu u naše ime, da izvršavamo usluge vezane za usluge ili da nam pomognemo da analiziramo kako se naša usluga koristi.</p>
        <p>Ove treće strane imaju pristup vašim ličnim podacima samo za obavljanje ovih zadataka u naše ime i obavezno da ne otkrivate ili ne koristite za bilo koju drugu svrhu.</p>

        <h3>Analitika</h3>
        <p>Možemo da koristimo usluge provajdera trećih strana da pratimo i analiziramo upotrebu naše usluge.</p>    
        <ul>
                <li>
                <p><strong>Google analitika</strong></p>
                <p>Google analitika je usluga web analitike koju nudi Google koji prati i izveštava saobraćaj na veb lokaciji. Google koristi prikupljene podatke da prati korišćenje naše usluge. Ovi podaci se dijele sa drugim Google uslugama. Google može da koristi prikupljene podatke da kontekstualizuje i personalizuje oglase sopstvene oglašivačke mreže.</p>
                <p>Možete da odbijete da obavite svoju aktivnost na Službi koja je dostupna Google analitici tako što ćete instalirati dodatak za brisanje optičkog pretraživača Google analitike. Ovaj dodatak sprečava JavaScript Google Analytics (ga.js, analytics.js i dc.js) da deli informacije sa Google analitikom o aktivnostima poseta.</p> 
                <p>Više informacija o praksama privatnosti Google-a potražite na veb stranici Google privatnosti i uslova: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
            </li>
                                    </ul>


        <h2>Linkovi na druge stranice</h2>
        <p>Naša usluga može sadržati veze sa drugim sajtovima na kojima ne radimo. Ako kliknete na vezu treće strane, bićete upućeni na stranicu trećeg lica. Savetujemo vam da pregledate Politiku privatnosti za svaku stranicu koju posjetite.</p>
        <p>Mi nemamo kontrolu i ne preuzimamo nikakvu odgovornost za sadržaj, politiku privatnosti ili praksu bilo koje treće strane ili usluga.</p>


        <h2>Privatnost djece</h2>
        <p>Naša služba ne adresira nikoga mlađu od 18 godina ("Djeca").</p>
        <p>Mi ne prikupljamo lične podatke od bilo koga mlađeg od 18 godina znajući to. Ako ste roditelj ili staratelj i svjesni ste da nam je Vaša djeca pružila lične podatke, molimo kontaktirajte nas. Ako postanemo svesni da smo prikupili lične podatke od dece bez provere saglasnosti roditelja, preduzimamo korake da uklonimo te informacije sa naših servera.</p>


        <h2>Izmene ove Politike privatnosti</h2>
        <p>S vremena na vrijeme možemo da ažuriramo našu Politiku privatnosti. Obaveštavamo vas o svim promenama postavljanjem nove Politike privatnosti na ovoj stranici.</p>
        <p>Obaveštavamo vas putem e-pošte i / ili istaknutog obaveštenja na našem servisu, pre nego što promena postane delotvorna i ažuriramo "datum stupanja na snagu" na vrhu ove Politike privatnosti.</p>
        <p>Savetujemo da periodično pregledate ovu Politiku privatnosti za sve promjene. Promjene ove Politike privatnosti važe kada se objavljuju na ovoj stranici.</p>


        <h2>Kontaktirajte nas</h2>
        <p>Ako imate pitanja o ovoj Politici privatnosti, molimo vas da nas kontaktirate:</p>
        <ul>
        <li>E-poštom: info@vipcars.ba</li>
            <li>Posjetivši ovu stranicu na našoj web stranici: vipcars.ba</li>
        <li>Broj telefona: 033 638 467 / 033 762 470 / 062 520 556</li>
        </ul>     
      </div>
   </ScrollAnimation>
    <Footer/>
   </Layout>
)

export default PrivacyPolicyPage
